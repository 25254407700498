import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import Plyr from "plyr";

export default function Player({
  id,
  name,
  peaks,
  audioUrl,
  onPlay,
  activeTrack,
  thumbnailUrl,
}) {
  const classes = useStyle();

  const [playing, setPlaying] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const handlePlayPause = () => {
    if (!playing) {
      setTimeout(() => {
        onPlay(id);
      }, 100);
      setPlaying(true);
    }

    if (!initialized) {
      let player = new Plyr(`#${id}`, {
        controls: ["play", "progress", "duration"],
      });

      document.getElementById(id).addEventListener("click", (e) => {
        e.stopPropagation();
      });
      document.getElementById(id).addEventListener("play", handlePlay);
      setInitialized(true);
    }
  };

  const handlePlay = () => {
    if (!document.getElementById(id).src?.startsWith("https")) {
      document.getElementById(id).src = audioUrl;
    }
  };

  useEffect(() => {
    if (id !== activeTrack) {
      setPlaying(false);
      document.getElementById(id).pause();
    }
  }, [activeTrack]);

  return (
    <div
      id={`container-${id}`}
      onClick={() => {
        handlePlayPause();
        setPlaying(true);
      }}
      className={`${classes.player} ${playing ? "active" : ""}`}
    >
      <span
        className={classes.name}
        onClick={() => {
          if (playing) {
            setPlaying(false);
          }
        }}
      >
        {name}
      </span>
      <audio id={id} />
    </div>
  );
}

const useStyle = createUseStyles({
  player: {
    padding: 10,
    fontSize: 24,
    fontWeight: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "all 0.2s",
    cursor: "pointer",

    "&:hover": {
      // backgroundColor: "#f7f7f780",
    },
    "&.active": {
      // backgroundColor: "#f7f7f7",
      borderRadius: 12,

      "& .plyr, & .plyr__controls": {
        backgroundColor: "transparent",
      },
      "& .plyr": {
        display: "unset",
      },
      "& audio": {},
    },

    "& .plyr": {
      display: "none",
    },
  },
  name: {
    padding: 10,
    color: "#757575",
    textAlign: "center",
  },
});
