import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { Link, useNavigate } from "react-router-dom";

export default function Menu() {
  const navigate = useNavigate();
  const classes = useStyle();

  return (
    <div className={classes.minimixesPage}>
      <div className={classes.backBtnWrapper}>
        <Button
          size="large"
          icon={<LeftOutlined />}
          onClick={() => navigate("/")}
        />
      </div>

      <Link to="/bio" style={{ textDecoration: "none" }}>
        <div className={classes.link}>BIO</div>
      </Link>
      <Link to="/minimixes" style={{ textDecoration: "none" }}>
        <div className={classes.link}>MIXES</div>
      </Link>
      <Link to="/bookings" style={{ textDecoration: "none" }}>
        <div className={classes.link}>BOOKINGS</div>
      </Link>
    </div>
  );
}

const useStyle = createUseStyles({
  minimixesPage: {
    width: "100vw",
    minHeight: "100vh",
    overflowX: "hidden",
    overflowY: "scroll",
    paddingTop: 80,
    paddingBottom: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    background: "#fff",
  },
  backBtnWrapper: {
    width: "100%",
    maxWidth: 800,
    position: "absolute",
    top: 0,
    padding: 30,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 2,
    "& .ant-btn": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  link: {
    padding: 10,
    margin: [20, 0],
    fontSize: 24,
    color: "#757575",
    transition: "all .2s",
    textDecoration: "none !important",
    "&:hover": {
      color: "#121212",
    },
  },
});
