import "./App.css";
import "antd/dist/reset.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Minimixes from "./pages/Minimixes";
import { useEffect, useState } from "react";
import Menu from "./pages/Menu";
import Bio from "./pages/Bio";

function App() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => window.location.reload());
    setIsDesktop(
      window.innerWidth > 768 && window.innerWidth > window.innerHeight
    );
  }, []);

  return (
    <div className={`App ${isDesktop ? "white-bg" : ""}`}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/bio" element={<Bio />} />
        <Route path="/bookings" element={<Contact />} />
        <Route path="/minimixes" element={<Minimixes />} />
      </Routes>
    </div>
  );
}

export default App;
