import React, { useEffect, useRef, useState } from "react";
import Menu from "../components/Menu";
import NathanImg from "../assets/nathan.png";
import clsx from "clsx";

export default function Home() {
  const VideoRef = useRef(null);
  const [isDesktop, setIsDesktop] = useState(false);
  const [loading, setloading] = useState(true);
  const [start, setStart] = useState(false);
  const [fade, setFade] = useState(false);

  const effect = () => {
    setTimeout(() => {
      setFade(true);
      setTimeout(() => {
        setFade((curr) => !curr);
      }, 6000);
    }, 8500);
  };

  const handleEnd = () => {
    VideoRef.current.play();
    // effect();
  };

  useEffect(() => {
    window.addEventListener("resize", () => window.location.reload());

    setIsDesktop(
      window.innerWidth > 768 && window.innerWidth > window.innerHeight
    );
    setloading(false);
  }, []);

  useEffect(() => {
    if (start) {
      effect();
    }
  }, [start]);

  if (loading) {
    return null;
  }

  return (
    <div
      className={clsx("homepage", {
        invisible: !start,
      })}
    >
      <Menu />

      <video
        ref={VideoRef}
        onEnded={handleEnd}
        preload="true"
        onLoadedDataCapture={() => {
          setStart(true);
        }}
        className={`video ${isDesktop ? "desktop" : "mobile"}`}
        muted
        autoPlay
        playsInline
      >
        <source
          src={`/videos/${isDesktop ? "desktop" : "mobile"}.mp4`}
          type="video/mp4"
        />
      </video>
      {/* <img
          src={"/videos/desktop.gif"}
          alt="Nathan"
          className={`video ${isDesktop ? "desktop" : "mobile"}`}
        /> */}
      <img
        src={NathanImg}
        alt="Nathan"
        className={`image ${isDesktop ? "desktop" : "mobile"}`}
      />
      {/* <div>
          <video preload className="video mobile" loop muted autoPlay>
            <source src="/videos/NE_PHONE_1.mp4" type="video/mp4" />
          </video>
          <img src={NathanImg} alt="Nathan" className="image mobile" />
        </div> */}
    </div>
  );
}
