import { FireOutlined, MenuOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

export default function Menu() {
  const classes = useStyle();

  return (
    <Link to="/menu">
      <Button icon={<MenuOutlined />} type="text" className={classes.menuBtn} />
    </Link>
  );
}

const useStyle = createUseStyles({
  menuBtn: {
    position: "absolute",
    top: 30,
    right: 30,
    zIndex: 500,
    "& .anticon": {
      fontSize: "24px !important",
    },
  },
});
