import React from "react";
import App from "./App";
import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";

export default function Root() {
  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "'Linear Beam'",
          },
        }}
      >
        <App />
      </ConfigProvider>
    </BrowserRouter>
  );
}
