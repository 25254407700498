import { LeftOutlined } from '@ant-design/icons';
// import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

export default function Contact() {
  const navigate = useNavigate();
  const classes = useStyle();

  return (
    <div className={classes.contactPage}>
      <div className={classes.backBtnWrapper}>
        <Button
          size="large"
          icon={<LeftOutlined />}
          onClick={() => navigate('/menu')}
        />
      </div>
      <div className={classes.contactBox}>
        {/* <div>
          <Typography.Title level={1} strong className={classes.name}>
            Nathan Ess
          </Typography.Title>
          <Typography.Title
            level={2}
            type="secondary"
            className={classes.compant}
          >
            Muddled Marauders
          </Typography.Title>
        </div> */}
        <div className={classes.contactOptions}>
          <a href="mailto:reception@muddledmarauders.co.uk">
            <Button
              size="large"
              //icon={<MailOutlined />}
              type="text"
            >
              reception@muddledmarauders.co.uk
            </Button>
          </a>

          <a
            href="tel:07802365501"
            style={{ marginTop: 20 }}
          >
            <Button
              size="large"
              // icon={<PhoneOutlined rotate={90} />}
              type="text"
            >
              07802365501
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

const useStyle = createUseStyles({
  contactPage: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: 30,
  },
  backBtnWrapper: {
    width: '100%',
    maxWidth: 800,
    position: 'absolute',
    top: 0,
    padding: 30,
    left: '50%',
    transform: 'translateX(-50%)',
    '& .ant-btn': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  contactBox: {
    width: '100%',
    maxWidth: 800,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    margin: '0 0 10px !important',
  },
  compant: {
    margin: '0 !important',
  },
  contactOptions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .ant-btn': {
      fontSize: 18,
    },
  },
  '@media (max-width: 768px)': {
    contactBox: {
      display: 'block',
    },
    contactOptions: {
      marginTop: 70,
    },
  },
  '@media (max-width: 425px)': {
    contactOptions: {
      '& .ant-btn': {
        fontSize: 14,
      },
    },
  },
});
